import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './styles/index.scss'
import installIcons from '@/icons'
import './permission'
import request from '@/utils/request'
import i18n from '@/i18n'

import { serviceOptions } from '@/swagger/services/index.ts'
import { serviceOptions as authService } from '@/swagger/services/auth.ts'

import installDirective from '@/directives'

// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 设置 swagger 生成的 api 的 axios
serviceOptions.axios = request
authService.axios = request

const app = createApp(App)
installElementPlus(app)
installIcons(app)
installDirective(app)
app.use(i18n)

// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//   app.component(key, component)
// }
app.use(store).use(router).mount('#app')
