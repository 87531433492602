import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn'
import elementEnLocale from 'element-plus/lib/locale/lang/en'
import zh from './lang/zh-CN.json'
import en from './lang/en.json'
import { getLang } from '@/utils/helper'

import { createI18n } from 'vue-i18n'

const lang = getLang()
const i18n = createI18n({
  locale: lang, // set locale
  fallbackLocale: 'zh-CN',
  legacy: false,
  globalInjection: true,
  messages: {
    'zh-CN': {
      ...zh,
      ...elementZhLocale
    },
    en: {
      ...en,
      ...elementEnLocale
    }
  }
})

export default i18n
