<template>
  <el-config-provider :locale="localLanguage">
    <router-view></router-view>
  </el-config-provider>
</template>
<script setup>
import { computed } from 'vue'
import i18n from '@/i18n'

// 多语言
const locale = i18n.global.locale
const localLanguage = computed(() => {
  return i18n.global.messages.value[locale.value]
})
</script>
<style lang="scss" scoped></style>
