import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '@/layout/index'

/**
 * 公开路由表
 */
const publicRoutes = [
  // {
  //   path: '/login',
  //   component: () => import('@/views/login/index')
  // },
  // {
  //   path: '/register',
  //   component: () => import('@/views/register/index')
  // },
  // {
  //   path: '/registerTip',
  //   component: () => import('@/views/registerTip/index')
  // },
  {
    path: '/',
    // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
    component: layout,
    redirect: '/login',
    children: [
      {
        path: '/login',
        component: () => import('@/views/login/index')
      },
      {
        path: '/register',
        component: () => import('@/views/register/index')
      },
      {
        path: '/registerTip',
        component: () => import('@/views/registerTip/index')
      },
      {
        path: '/site',
        name: 'site',
        component: () => import('@/views/site/index'),
        meta: {
          title: 'site', // 'siteConfig',
          icon: 'role'
        }
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/index'),
        meta: {
          title: 'user',
          icon: 'role'
        }
      },
      {
        path: '/license',
        name: 'license',
        component: () => import('@/views/license/index/'),
        meta: {
          title: 'license'
        }
      },
      {
        path: '/createNewSite',
        name: 'createNewSite',
        component: () => import('@/views/site/components/createNewSite'),
        meta: {
          title: 'createNewSite'
        }
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/401')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...publicRoutes]
})
export default router
