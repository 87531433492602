<template>
  <div class="navbar">
    <div style="display: flex; align-items: center">
      <img src="../../assets/imgs/logo.png" alt="" />
      <Time></Time>
    </div>

    <div class="right-menu">
      <div v-if="store.getters.token" id="logoutBtn" @click="logout">
        {{ t('logout') }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import Time from '@/components/time'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const store = useStore()
const logout = () => {
  store.dispatch('user/logout')
}
</script>
<style lang="scss" scoped>
#logoutBtn {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: #ffffff;
  width: 144px;
  height: 48px;
  line-height: 48px;
  text-align: center;
}
.navbar {
  padding: 32px 50px;
  overflow: hidden;
  position: relative;
  background: transparent;
  display: flex;
  justify-content: space-between;
  img {
    height: 32px;
    margin-right: 48px;
  }
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    display: flex;
    align-items: center;
    line-height: 46px;
    height: 100%;

    ::v-deep .right-menu-item {
      display: inline-block;
      padding: 0 18px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;
      &.hover-effect {
        cursor: pointer;
      }
    }
    ::v-deep .avatar-container {
      cursor: pointer;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
