import variables from '@/styles/variables.scss'
const getters = {
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  /**
   * @returns true 表示已存在用户信息
   */
  hasUserInfo: state => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  cssVar: state => variables,
  sidebarOpened: state => state.app.sidebarOpened,
  tagsViewList: state => state.app.tagsViewList,
  currentEngine: state => state.engine.currentEngine,
  // 任务采集器是否打开
  taskStatus: state => state.engine.taskStatus
  // signalrConnected: state => state.signalR.signalrConnected
}
export default getters
