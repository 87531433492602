<template>
  <div class="time-box">
    <div id="day">
      <span v-for="(x, i) in year" :key="`year_${i}`">{{ x }}</span>
      <span>.{{ month }}</span>
      <span>.{{ day }}</span>
    </div>
    <div id="time" class="time">{{ time }}</div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
const date = ref(new Date())
const year = computed(() => date.value.getFullYear().toString().split(''))
const month = computed(() => greater(date.value.getMonth() + 1))
const day = computed(() => greater(date.value.getDate()))
const time = computed(() => `${greater(date.value.getHours())}:${greater(date.value.getMinutes())}`)
const timeId = ref(null)

const greater = num => {
  return Number(num) > 9 ? num : '0' + num
}

onMounted(() => {
  if (timeId.value) clearInterval(timeId.value)
  timeId.value = setInterval(() => (date.value = new Date()), 1000)
})
</script>

<style lang="scss" scoped>
.time-box {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: var(--time-color);
  #day {
    font-weight: 500;
  }
  #time {
    margin-left: 24px;
  }
}
</style>
