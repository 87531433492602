function checkPermission(el, binding) {
  const { value: taskStatus } = binding

  // 任务采集是启用状态

  if (taskStatus === 0) {
    if (el.type === 'button') {
      el.disabled = true
      el.classList.add('is-disabled')
    } else {
      el.style.display = 'none'
    }
  } else {
    if (el.type === 'button') {
      el.disabled = false

      el.classList.remove('is-disabled')
    } else {
      el.style.display = 'unset'
    }
  }
}

export default {
  mounted(el, binding) {
    checkPermission(el, binding)
  },
  updated(el, binding) {
    checkPermission(el, binding)
  }
}
