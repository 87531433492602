/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   * 登录操作
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 添加账户
   */
  static add(
    params: {
      /** requestBody */
      body?: RegisterViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除账户
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除一组账户
   */
  static deleteBatch(
    params: {
      /** requestBody */
      body?: IntArrayViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/DeleteBatch';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 冻结账户
   */
  static frozen(
    params: {
      /** requestBody */
      body?: AccountStatusViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Frozen';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改账户角色
   */
  static updateRole(
    params: {
      /** requestBody */
      body?: AccountRolesViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/UpdateRole';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新账户
   */
  static update(
    params: {
      /** requestBody */
      body?: AccountCommonViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新账户密码
   */
  static updatePassword(
    params: {
      /** requestBody */
      body?: AccountPasswordModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/UpdatePassword';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取账户
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有账户
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      sortName?: string;
      /**  */
      isDesc?: boolean;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 校验Token
   */
  static verifyToken(
    params: {
      /** 验证Token */
      token: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/VerifyToken';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ClaimService {
  /**
   * 扫描程序中的功能权限更新到数据库
   */
  static scan(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Claim/Scan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除功能权限
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Claim/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除一组功能权限
   */
  static deleteBatch(
    params: {
      /** requestBody */
      body?: IntArrayViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Claim/DeleteBatch';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新权限缓存
   */
  static refresh(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Claim/Refresh';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有功能权限
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      sortName?: string;
      /**  */
      isDesc?: boolean;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Claim/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取带分组信息的功能权限
   */
  static getAllWithGroup(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Claim/GetAllWithGroup';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ClaimRoleMapService {
  /**
   * 添加角色到功能的映射
   */
  static add(
    params: {
      /** requestBody */
      body?: ClaimRoleMapAddViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ClaimRoleMap/Add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 添加角色到功能的映射
   */
  static addBatch(
    params: {
      /** requestBody */
      body?: ClaimRoleMapAddBatchViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ClaimRoleMap/AddBatch';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 清除所有并添加角色到功能的映射
   */
  static delInsert(
    params: {
      /** requestBody */
      body?: ClaimRoleMapAddBatchViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ClaimRoleMap/DelInsert';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除角色到功能的映射
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ClaimRoleMap/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除一组角色到功能的映射
   */
  static deleteBatch(
    params: {
      /** requestBody */
      body?: IntArrayViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ClaimRoleMap/DeleteBatch';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新角色到功能的映射
   */
  static update(
    params: {
      /** requestBody */
      body?: ClaimRoleMapCommonViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ClaimRoleMap/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新一组角色到功能的映射
   */
  static addOrUpdateBatch(
    params: {
      /** requestBody */
      body?: ClaimRoleMapCommonViewModel[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ClaimRoleMap/AddOrUpdateBatch';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有角色到功能的映射
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      sortName?: string;
      /**  */
      isDesc?: boolean;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ClaimRoleMap/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取某个角色到功能的映射
   */
  static getAllByRoleId(
    params: {
      /**  */
      roleId?: number;
      /**  */
      keyword?: string;
      /**  */
      sortName?: string;
      /**  */
      isDesc?: boolean;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ClaimRoleMap/GetAllByRoleId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        roleId: params['roleId'],
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DefaultService {
  /**
   * 默认动作
   */
  static index(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Default/Index';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoleService {
  /**
   * 添加角色
   */
  static add(
    params: {
      /** requestBody */
      body?: RoleViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Role/Add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除角色
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Role/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除一组角色
   */
  static deleteBatch(
    params: {
      /** requestBody */
      body?: IntArrayViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Role/DeleteBatch';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新角色
   */
  static update(
    params: {
      /** requestBody */
      body?: RoleViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Role/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有角色
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      sortName?: string;
      /**  */
      isDesc?: boolean;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Role/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AccountCommonViewModel {
  /** 账号索引ID */
  id?: number;

  /** 名称 */
  name?: string;

  /** 别名 */
  alias?: string;
}

export interface AccountPasswordModel {
  /** 用户ID */
  id?: number;

  /** 用户原密码 */
  oldPassword?: string;

  /** 用户新密码 */
  newPassword?: string;
}

export interface AccountRolesViewModel {
  /** 用户ID索引 */
  id?: number;

  /** 角色ID */
  roleId?: number;
}

export interface AccountStatusViewModel {
  /** ID */
  id?: number;

  /** 是否冻结 */
  frozen?: boolean;
}

export interface ClaimRoleMapAddBatchViewModel {
  /** 角色索引ID */
  roleId?: number;

  /** 权限组索引ID */
  claimIds?: number[];
}

export interface ClaimRoleMapAddViewModel {
  /** 角色索引ID */
  roleId?: number;

  /** 权限组索引ID */
  claimId?: number;
}

export interface ClaimRoleMapCommonViewModel {
  /** 角色权限组映射索引ID */
  id?: number;

  /** 角色索引ID */
  roleId?: number;

  /** 权限组索引ID */
  claimId?: number;
}

export interface IntArrayViewModel {
  /** 数值索引ID列表 */
  value?: number[];
}

export interface LoginViewModel {
  /** 用户名 */
  userName: string;

  /** 密码 */
  userPassword: string;
}

export interface RegisterViewModel {
  /** 用户名 */
  name?: string;

  /** 密码 */
  password?: string;

  /** 角色ID */
  roleId?: number;

  /** 别名 */
  alias?: string;
}

export interface RoleViewModel {
  /** 角色索引ID */
  id?: number;

  /** 角色名称(英文名) */
  name?: string;

  /** 角色别名（中文名） */
  alias?: string;
}
