import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'
import { isCheckTimeout } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000
})

service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      if (isCheckTimeout()) {
        store.dispatch('user/logout')
        return Promise.reject(new Error('token 失效'))
      }
      config.headers.Authorization = `Bearer ${store.getters.token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    return response.data
    // const { success, msg } = response.data
    // if (success) {
    //   return response
    // } else {
    //   ElMessage.error(msg)
    //   return Promise.reject(new Error(msg))
    // }
  },
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch('user/logout')
    } else if (error.response && error.response.status === 500) {
      if (error.response.data.path === '/userlogin') {
        ElMessage.error(error.response.data.error + ', Or Unauthorized')
      }
    } else {
      ElMessage.error(error.message)
    }

    return Promise.reject(error)
  }
)

export default service
