import router from './router'
import store from './store'

// 白名单
const whiteList = ['/login', '/register', '/registerTip']
/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 存在 token ，进入主页
  // if (store.state.user.token) {
  // 快捷访问
  if (store.getters.token) {
    if (to.path === '/login') {
      if (store.getters.userInfo.userRole === '1') {
        next('/user')
      } else if (store.getters.userInfo.userRole === '0') {
        next('/site')
      }
      next()
    } else {
      // 判断用户资料是否获取
      // 若不存在用户信息，则需要获取用户信息
      if (!store.getters.hasUserInfo) {
        // 触发获取用户信息的 action
        const userId = store.getters.userInfo.userId
        await store.dispatch('user/getUserInfo', userId)
      }
      next()
    }
  } else {
    // 没有token的情况下，可以进入白名单
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
