// import md5 from 'md5'
import { getItem, setItem, removeAllItem } from '../../utils/storage'
import { TOKEN, USERINFO } from '@/constant'
import router from '@/router'
import { setTimeStamp } from '@/utils/auth'
import { getUserInfo, login } from '@/api/sys'

const role = new Map()
role.set('admin', '1')

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: getItem('userInfo') || {}
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      setItem(USERINFO, userInfo)
    }
  },
  actions: {
    /**
     * @description: 处理登录
     * @param {*} context
     * @param {*} userInfo
     */
    async login(context, userInfo) {
      const { username, password } = userInfo

      return new Promise((resolve, reject) => {
        login({
          userid: username,
          password: password
        })
          .then(data => {
            this.commit('user/setToken', data.content.token)
            this.commit('user/setUserInfo', {
              userId: data.content.userid,
              userRole: data.content.role
            })
            // 跳转
            if (context.state.userInfo.userRole === role.get('admin')) {
              router.push('/user')
            } else {
              router.push('/license')
            }
            setTimeStamp()
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    async getUserInfo(context, userId) {
      const res = await getUserInfo(userId)
      this.commit('user/setUserInfo', res)
      return res
    },
    /**
     * @description: 登出
     */
    logout() {
      this.commit('user/setToken', '')
      this.commit('user/setUserInfo', {})
      removeAllItem()
      router.push('/login')
    }
  }
}
