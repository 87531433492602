<template>
  <div class="app-main">
    <div class="component">
      <router-view v-slot="{ Component, route }">
        <!-- <transition name="fade-transform" mode="out-in"> -->
        <keep-alive>
          <component :is="Component" :key="route.path" />
        </keep-alive>
        <!-- </transition> -->
      </router-view>
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { isTags } from '@/utils/tags'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()

const getTitle = route => {
  let title = ''
  if (!route.meta) {
    // 处理无 meta 的路由
    const pathArr = route.path.split('/')
    title = pathArr[pathArr.length - 1]
  } else {
    title = route.meta.title
  }
  return title
}

const store = useStore()
watch(
  route,
  (to, from) => {
    if (!isTags(to.path)) return
    const { fullPath, meta, name, params, path, query } = to
    store.commit('app/addTagsViewList', {
      fullPath,
      meta,
      name,
      params,
      path,
      query,
      title: getTitle(to)
    })
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.app-main {
  // min-height: calc(100vh - 90px);
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  flex: auto;
  padding: 0 50px;
  padding-bottom: 50px;
}
.component {
  background-image: var(--bg-main-card);
  border-radius: 8px;
  height: 100%;
}
</style>
