export const TOKEN = 'token'
export const USERINFO = 'userInfo'
// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000
// tags
export const TAGS_VIEW = 'tagsView'

// channel 通道 数据 的分类
export const NET_WORK = '网络'
export const COMMUNICATION = '通讯'
export const COMMON = '通常'
export const AGREEMENT = '协议'
export const MAX_RUN_LOG_COUNTER = 80

// 隐藏运行日志的时候， DOM 的宽度
export const hideRunLogWidth = '25px'
// 显示运行日志的时候， DOM 的宽度
export const showRunLogWidth = '360px'
