/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios'

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any
  headers?: any
  url?: any
  data?: any
  params?: any
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance
}

// Add default options
export const serviceOptions: ServiceOptions = {}

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  } else {
    throw new Error('please inject yourself instance like axios  ')
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url }
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  }
  return configs
}

export const basePath = ''

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[]
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[]
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number
  items?: T[]
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number
  items?: T[]
}

// customer definition
// empty

export class CommChannelService {
  /**
   * 添加通道
   */
  static add(
    params: {
      /** requestBody */
      body?: ChannelCreateViewModel
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommChannel/Add'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 删除通道
   */
  static delete(
    params: {
      /** 通道ID */
      chnId?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommChannel/Delete'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options
      )
      configs.params = { ChnId: params['chnId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 修改通道
   */
  static update(
    params: {
      /** requestBody */
      body?: ChnModifyViewModel
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommChannel/Update'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 启用禁用通道
   */
  static active(
    params: {
      /** 通道ID */
      chnId?: string
      /** 启用/禁用 */
      enable?: boolean
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommChannel/Active'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )
      configs.params = { ChnId: params['chnId'], Enable: params['enable'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 修改通道名称
   */
  static rename(
    params: {
      /** 通道ID */
      chnId?: string
      /** 通道名称 */
      name?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommChannel/Rename'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )
      configs.params = { ChnId: params['chnId'], Name: params['name'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 导入控制器
   */
  static importController(
    params: {
      /** 通道ID */
      chnId?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommChannel/ImportController'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      )
      configs.params = { ChnId: params['chnId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取单个指定通道
   */
  static get(
    params: {
      /** 通道ID */
      chnId?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommChannel/Get'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = { ChnId: params['chnId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取所有通信通道树结构
   */
  static getTree(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommChannel/GetTree'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取所有通道
   */
  static getAll(
    params: {
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommChannel/GetAll'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取所有通道
   */
  static getAllByOriginal(
    params: {
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommChannel/GetAllByOriginal'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class CommControllerService {
  /**
   * 新增通信控制器实例
   */
  static add(
    params: {
      /**  */
      chnId?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/Add'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      )
      configs.params = { ChnId: params['chnId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 删除通信控制器实例
   */
  static delete(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/Delete'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options
      )
      configs.params = { ChnId: params['chnId'], ConId: params['conId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 修改通信控制器参数
   */
  static update(
    params: {
      /** requestBody */
      body?: ConModifyViewModel
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/Update'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 启用禁用通道
   */
  static active(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 启用/禁用 */
      enable?: boolean
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/Active'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        Enable: params['enable']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 修改通信控制器名称
   */
  static rename(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 通道名称 */
      name?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/Rename'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        Name: params['name']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 新增变量
   */
  static addVariable(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 自动创建 */
      auto?: boolean
      /** 变量名称 */
      name?: string
      /** 变量地址 */
      addr?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/AddVariable'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        Auto: params['auto'],
        Name: params['name'],
        Addr: params['addr']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 导入变量
   */
  static importVariable(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/ImportVariable'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      )
      configs.params = { ChnId: params['chnId'], ConId: params['conId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取单个指定的控制器
   */
  static get(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/Get'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = { ChnId: params['chnId'], ConId: params['conId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取工程下的所有控制器
   */
  static getAll(
    params: {
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/GetAll'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取某个通道下的所有控制器
   */
  static getAllControllerOfChn(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/GetAllControllerOfChn'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取某个通道下的所有控制器
   */
  static getAllControllerOfChnOriginal(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommController/GetAllControllerOfChnOriginal'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class CommDriverService {
  /**
   * 上传通信驱动
   */
  static import(
    params: {
      /**  */
      files: []
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommDriver/Import'

      const configs: IRequestConfig = getConfigs(
        'post',
        'multipart/form-data',
        url,
        options
      )

      let data = null
      data = new FormData()
      if (params['files']) {
        if (
          Object.prototype.toString.call(params['files']) === '[object Array]'
        ) {
          for (const item of params['files']) {
            data.append('Files', item as any)
          }
        } else {
          data.append('Files', params['files'] as any)
        }
      }

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 删除通信驱动库文件
   */
  static delete(
    params: {
      /** 通信驱动库文件名 */
      name?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommDriver/Delete'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options
      )
      configs.params = { Name: params['name'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取通道物理接口类型
   */
  static getCommInterface(
    params: {
      /** 协议编码 */
      code?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommDriver/GetCommInterface'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      )
      configs.params = { Code: params['code'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取所有通信驱动
   */
  static getDrivers(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommDriver/GetDrivers'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取所有通信驱动库文件
   */
  static getAll(
    params: {
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommDriver/GetAll'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class CommVariableService {
  /**
   * 新增通信变量
   */
  static add(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 自动创建 */
      auto?: boolean
      /** 变量名称 */
      name?: string
      /** 变量地址 */
      addr?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/Add'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        Auto: params['auto'],
        Name: params['name'],
        Addr: params['addr']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 删除通信变量
   */
  static delete(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 通信变量ID */
      varId?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/Delete'

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        VarId: params['varId']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 修改通信变量参数
   */
  static update(
    params: {
      /** requestBody */
      body?: VarModifyViewModel
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/Update'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 启用禁用通信变量
   */
  static active(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 通信变量ID */
      varId?: string
      /** 启用/禁用 */
      enable?: boolean
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/Active'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        VarId: params['varId'],
        Enable: params['enable']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 修改通信变量名称
   */
  static rename(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 通信变量ID */
      varId?: string
      /** 通道名称 */
      name?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/Rename'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        VarId: params['varId'],
        Name: params['name']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 变量设定
   */
  static write(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 通信变量ID */
      varId?: string
      /** 通道名称 */
      value?: string | number | boolean
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/Write'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        VarId: params['varId'],
        Value: params['value']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取单个指定的通信变量
   */
  static get(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 通信变量ID */
      varId?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/Get'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        VarId: params['varId']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取某个通信通道的所有通信变量
   */
  static getAllVarsOfChn(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/GetAllVarsOfChn'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取某个控制器的所有通信变量
   */
  static getAllVarsOfCon(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/GetAllVarsOfCon'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取某个控制器的所有通信变量
   */
  static getAllVarsOfConOriginal(
    params: {
      /** 通信通道ID */
      chnId?: string
      /** 通信控制器ID */
      conId?: string
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/GetAllVarsOfConOriginal'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        ChnId: params['chnId'],
        ConId: params['conId'],
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取工程所有的通信变量
   */
  static getAll(
    params: {
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/GetAll'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取工程所有的通信变量
   */
  static getAllOriginal(
    params: {
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CommVariable/GetAllOriginal'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class DefaultService {
  /**
   * 默认动作
   */
  static index(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Default/Index'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class ProCfgService {
  /**
   * 新增工程配置文件
   */
  static add(
    params: {
      /**  */
      name?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ProCfg/Add'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      )
      configs.params = { Name: params['name'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 上传工程配置文件
   */
  static import(
    params: {
      /**  */
      name: string
      /**  */
      file: any
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ProCfg/Import'

      const configs: IRequestConfig = getConfigs(
        'post',
        'multipart/form-data',
        url,
        options
      )

      let data = null
      data = new FormData()
      if (params['name']) {
        if (
          Object.prototype.toString.call(params['name']) === '[object Array]'
        ) {
          for (const item of params['name']) {
            data.append('Name', item as any)
          }
        } else {
          data.append('Name', params['name'] as any)
        }
      }

      if (params['file']) {
        if (
          Object.prototype.toString.call(params['file']) === '[object Array]'
        ) {
          for (const item of params['file']) {
            data.append('File', item as any)
          }
        } else {
          data.append('File', params['file'] as any)
        }
      }

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 删除工程配置文件
   */
  static delete(
    params: {
      /**  */
      fileName?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ProCfg/Delete'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )
      configs.params = { FileName: params['fileName'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 激活配置工程(无后缀)
   */
  static active(
    params: {
      /**  */
      fileName?: string
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ProCfg/Active'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )
      configs.params = { FileName: params['fileName'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取当前的工程配置文件(无后缀)
   */
  static getActiveProCfg(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ProCfg/GetActiveProCfg'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 导出工程配置文件
   */
  static export(
    params: {
      /**  */
      name?: string
      /**  */
      needDownload?: boolean
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ProCfg/Export'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        Name: params['name'],
        needDownload: params['needDownload']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取所有工程配置文件
   */
  static getAll(
    params: {
      /** 查询字段(多字段查询，空格分隔) */
      keyword?: string
      /** 按字段名排序(Key) */
      sortName?: string
      /** 是否降序排列 */
      isDesc?: boolean
      /** 页码索引[1,X) */
      pageNumber?: number
      /** 每页条数 */
      pageSize?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ProCfg/GetAll'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class SysCfgService {
  /**
   * 更新系统配置
   */
  static update(
    params: {
      /** requestBody */
      body?: SysCfgEditViewModel
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SysCfg/Update'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取系统配置
   */
  static get(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SysCfg/Get'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取服务状态
   */
  static getServiceStatus(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SysCfg/GetServiceStatus'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 启动采集任务
   */
  static start(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SysCfg/Start'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 停止采集任务
   */
  static stop(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SysCfg/Stop'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class ZLogService {
  /**
   * 添加ZLog日志
   */
  static add(
    params: {
      /** requestBody */
      body?: ZLogViewModel
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ZLog/Add'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 获取日志
   */
  static getAll(
    params: {
      /**  */
      keyword?: string
      /**  */
      sortName?: string
      /**  */
      isDesc?: boolean
      /**  */
      pageNumber?: number
      /**  */
      pageSize?: number
      /**  */
      start?: string
      /**  */
      end?: string
      /**  */
      type?: string
      /**  */
      source?: string
      /**  */
      level?: NLogLevel
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ZLog/GetAll'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options
      )
      configs.params = {
        keyword: params['keyword'],
        sortName: params['sortName'],
        isDesc: params['isDesc'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        start: params['start'],
        end: params['end'],
        type: params['type'],
        source: params['source'],
        level: params['level']
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   * 保留最近n天数据,其它删除
   */
  static keepLogData(
    params: {
      /**  */
      dayNumber?: number
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ZLog/KeepLogData'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options
      )
      configs.params = { dayNumber: params['dayNumber'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export interface ChannelCreateViewModel {
  /** 协议编码 */
  code?: string

  /** 通讯接口类型 */
  type?: string
}

export interface ChnModifyViewModel {
  /** 通道ID */
  chnId?: string

  /** 修改参数集合 */
  params?: JsonEntity[]
}

export interface ConModifyViewModel {
  /** 通信通道ID */
  chnId?: string

  /** 通信控制器ID */
  conId?: string

  /** 修改参数集合 */
  params?: JsonEntity[]
}

export interface JsonEntity {
  /** 键 */
  k?: string

  /** 值 */
  v?: any | null
}

export interface SysCfgEditViewModel {
  /** 修改参数集合 */
  params?: JsonEntity[]
}

export interface VarModifyViewModel {
  /** 通信通道ID */
  chnId?: string

  /** 通信控制器ID */
  conId?: string

  /** 通信变量ID */
  varId?: string

  /** 修改参数集合 */
  params?: JsonEntity[]
}

export interface ZLogViewModel {
  /**  */
  idx?: number

  /**  */
  source?: string

  /**  */
  timestamp?: Date

  /**  */
  level?: string

  /**  */
  type?: string

  /**  */
  message?: string

  /**  */
  exception?: string
}

export type NLogLevel = 0 | 1 | 2 | 3 | 4 | 5 | 6
