import request from '@/utils/request'

/**
 * 登录
 */
export const login = data => {
  return request({
    url: '/userlogin',
    method: 'post',
    data
  })
}

/**
 * 获取当前user info
 */
export const getUserInfo = (userId = 0) => {
  return request({
    url: `/getCurLoginUserInfo/${userId}`,
    method: 'get'
  })
}
